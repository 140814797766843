<style lang="less" scoped>
  .time {
    position: absolute;
    top: 30px;
    font-weight: 800;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
  }
  .search-b {
    background: #fff;
    position: absolute;
    right: 25px;
    top: 18px;
    padding: 5px 10px;
  }
</style>

<template>
  <Card class="card-c" title="人员结构趋势分析">
    <div class="search-b">
      <span style="margin-right: 1rem;">人员类别</span>
      <fm-select absolute @change="$emit('jobTypeChange', jobType)" filterable v-model="jobType">
        <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
      </fm-select>
    </div>
    <div class="time" v-if="current">{{current}}趋势分析</div>
    <div class="c-s">
      <div class="c-i" ref="c1"></div>
      <div class="c-i" ref="c2"></div>
      <div class="c-i" ref="c3"></div>
    </div>
    <slider style="z-index: 1;" :data="sliderData" @change="month => current = month"/>
  </Card>
</template>

<script>
import Card from './card'
import Slider from './slider'
const echarts = require('echarts')

export default {
  components: {
    Card, Slider
  },
  props: {
    ageData: { type: Array, default: null },
    eduData: { type: Array, default: null },
    jobData: { type: Array, default: null },
    eduList: { type: Array },
    jobList: { type: Array }
  },
  data () {
    return {
      jobType: 'all',
      chart1: null,
      chart2: null,
      chart3: null,
      current: null
    }
  },
  computed: {
    workerJobTypes () {
      return [{key: 'all', label: '全部'}].concat(this.$store.getters.selectDatas['worker_job_type'])
    },
    sliderData () {
      return Array.from(new Set([...this.ageData.map(v => v.month), ...this.eduData.map(v => v.month), ...this.jobData.map(v => v.month)]))
    }
  },
  watch: {
    ageData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw()
      }
    },
    eduData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw2()
      }
    },
    jobData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw3()
      }
    },
    current: {
      immediate: true,
      handler () {
        this.update()
      }
    }
  },
  methods: {
    getOptions ({title, y, d1, d2, maxd}) {
      function labelFormatter (param) {
        const a = [d2, d1][(param.seriesIndex + 1) % 2][param.dataIndex]
        const b = [d2, d1][param.seriesIndex][param.dataIndex]
        return ((b > a) || (a === b && param.seriesIndex === 1)) ? ((a + b) || '') : ''
      }
      return {
        title: {
          text: title,
          bottom: '14',
          right: '50%',
          left: '50%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14,
            color: '#262626',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          formatter (param) {
            return param[0].name + '（' + param.reduce((a, b) => a + b.data, 0) + '人）<br />' + param.map(v => {
              return v.marker + ' ' + v.seriesName + ': ' + v.data + '人'
            }).join('<br />')
          }
        },
        legend: { show: false },
        color: ['#F4628F', '#FFCFD7'],
        grid: { left: '3%', right: '4%', bottom: '50', top: '30', containLabel: true },
        xAxis: {
            type: 'value',
            position: 'top',
            axisLabel: { color: 'rgba(0,0,0,0.45)' },
            max: (parseInt(maxd / 20) + 1) * 20,
            offset: -0
        },
        yAxis: {
          type: 'category',
          name: '（单位:人）',
          nameLocation: 'end',
          nameTextStyle: {
            color: 'rgba(0, 0, 0, 0.45)',
            align: 'right'
          },
          data: y,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: 'rgba(0,0,0,0.45)' },
          splitLine: {
            lineStyle: { color: '#E8E8E8' }
          }
        },
        series: [
          {
            name: '女',
            type: 'bar',
            stack: 'gender',
            label: {
              show: true, color: '#FFF', formatter: labelFormatter
            },
            data: d2
          },
          {
            name: '男',
            type: 'bar',
            stack: 'gender',
            barWidth: 16,
            label: {
              show: true, color: '#FFF', formatter: labelFormatter
            },
            data: d1
          },
        ]
      }
    },
    draw () {
      if (!this.$refs.c1 || !this.ageData) {
        return
      }
      let ageList = ['16-19岁', '20-29岁', '30-39岁', '40-49岁', '50-59岁', '60岁以上']
      if (this.ageData.length === 0) {
        this.chart1 = echarts.init(this.$refs.c1)
        this.chart1.setOption(this.getOptions({
          title: '年龄结构',
          d1: ageList.map(() => 0),
          d2: ageList.map(() => 0),
          y: ageList,
          maxd: 0
        }), true)
        return
      }
      if (!this.chart1) {
        this.chart1 = echarts.init(this.$refs.c1)
      }
      let ageData = this.current ? this.ageData.find(v => v.month === this.current).data : this.ageData[this.ageData.length - 1].data
      let ages = ['0', '20', '30', '40', '50', '60']
      let maxData = 10
      ages.forEach((v) => {
        if (ageData[v].m > maxData) {
          maxData = ageData[v].m
        }
        if (ageData[v].w > maxData) {
          maxData = ageData[v].w
        }
      })
      this.chart1.setOption(this.getOptions({
        title: '年龄结构',
        d1: ages.map(v => ageData[v].m),
        d2: ages.map(v => ageData[v].w),
        y: ageList,
        maxd: maxData
      }), true)
    },
    draw2 () {
      if (!this.$refs.c2 || !this.eduData) {
        return
      }
      if (this.eduData.length === 0) {
        this.chart2 = echarts.init(this.$refs.c2)
        this.chart2.setOption(this.getOptions({
          title: '学历结构',
          d1: this.eduList.map(() => 0),
          d2: this.eduList.map(() => 0),
          y: this.eduList,
          maxd: 0
        }), true)
        return
      }
      if (!this.chart2) {
        this.chart2 = echarts.init(this.$refs.c2)
      }
      let eduData = this.current ? this.eduData.find(v => v.month === this.current).data : this.eduData[this.eduData.length - 1].data
      let edus = eduData ? Object.keys(eduData) : []
      let maxData = 10
      edus.forEach((v) => {
        if (eduData[v].m > maxData) {
          maxData = eduData[v].m
        }
        if (eduData[v].w > maxData) {
          maxData = eduData[v].w
        }
      })
      this.chart2.setOption(this.getOptions({
        title: '学历结构',
        d1: edus.map(v => eduData[v].m),
        d2: edus.map(v => eduData[v].w),
        y: edus,
        maxd: maxData
      }), true)
    },
    draw3 () {
      if (!this.$refs.c3 || !this.jobData) {
        return
      }
      if (this.jobData.length === 0) {
        this.chart3 = echarts.init(this.$refs.c3)
        this.chart3.setOption(this.getOptions({
          title: '职称结构',
          d1: this.jobList.map(() => 0),
          d2: this.jobList.map(() => 0),
          y: this.jobList,
          maxd: 0
        }), true)
        return
      }
      if (!this.chart3) {
        this.chart3 = echarts.init(this.$refs.c3)
      }
      let jobDats = this.current ? this.jobData.find(v => v.month === this.current).data : this.jobData[this.jobData.length - 1].data
      let jobs = jobDats ? Object.keys(jobDats) : []
      let maxData = 10
      jobs.forEach((v) => {
        if (jobDats[v].m > maxData) {
          maxData = jobDats[v].m
        }
        if (jobDats[v].w > maxData) {
          maxData = jobDats[v].w
        }
      })
      this.chart3.setOption(this.getOptions({
        title: '职称结构',
        d1: jobs.map(v => jobDats[v].m),
        d2: jobs.map(v => jobDats[v].w),
        y: jobs,
        maxd: maxData
      }), true)
    },
    update () {
      if (!this.current && this.ageData && this.ageData.length) {
        this.current = this.ageData[this.ageData.length - 1].month
      }
      this.draw()
      this.draw2()
      this.draw3()
    }
  }
}
</script>

<style lang="less" scoped>
.c-s {
  width: 100%;
  display: flex;
  flex: 1;
  .c-i {
    flex: 1;
    margin: 0 25px;
    height: 100;
  }
}
.card-c {
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>