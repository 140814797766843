<template>
  <Card class="card-c" title="历史人数演变">
    <div class="c-s" ref="c1">
    </div>
  </Card>
</template>

<script>
import Card from './card'
const echarts = require('echarts')
export default {
  components: {
    Card
  },
  props: {
    entryData: { type: Array, default: () => {
      return []
    } }
  },
  data () {
    return {
      chart1: null
    }
  },
  watch: {
    entryData: {
      deep: true,
      immediate: true,
      handler (v) {
        v && this.draw()
      }
    }
  },
  methods: {
    getOptions () {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            shadowStyle: { color: '#FBEAEE', opacity: 0.3 },
            type: 'shadow'
          }
        },
        color: ['#FB6D8F'],
        grid: {
          left: '18px',
          right: '18px',
          bottom: '5',
          top: '10',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.entryData.map(v => v.month),
          axisTick: { alignWithLabel: true, show: false },
          axisLine: {
            lineStyle: { color: '#E8E8E8' }
          },
          axisLabel: { color: '#7F7F7F' }
        }],
        yAxis: [{ type: 'value', splitLine: { show: false }}],
        series: [
          {
            name: '',
            type: 'line',
            barWidth: 13,
            itemStyle: { borderRadius: 5 },
            emphasis: {
              itemStyle: {
                scaleSize: 10,
                borderColor: '#FFD9DB',
                borderWidth: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#FB6D8F' },
                  { offset: 1, color: '#FB6D8F' }
                ])
              }
            },
            data: this.entryData.map(v => v.num)
          }
        ]
      }
    },
    draw () {
      if (!this.$refs.c1) {
        return
      }
      if (!this.chart1) {
        this.chart1 = echarts.init(this.$refs.c1)
      }
      this.chart1.setOption(this.getOptions(), true)
    }
  },
  mounted () {
    this.draw()
  }
}
</script>

<style lang="less" scoped>
.c-s {
  width: 100%;
  display: flex;
  flex: 1;
  .c-i {
    flex: 1;
    height: 100%;
  }
}
.card-c {
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>