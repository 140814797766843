<style scoped lang="less">
  .work-card {
    border-radius: 8px;
    background-color: #FFF;
    .person-header {
      display: flex;
      justify-content: space-between;
      .title {
        padding-left: 20px;        
        font-size: 14px;
        font-weight: 800;
        color: #000;
        line-height: 40px;
      }
    }
  }
</style>

<template>
  <div class="work-card">
    <div class="person-header">
      <div class="title">{{title}}</div>
      <slot name="action"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' }
  }
}
</script>
