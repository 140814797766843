<style scoped lang="less">
  .slider {
    position: relative;
    height: 15px;
  }
  .points {
    background-image: url('/static/images/bg-bar.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 100%;
    .point {
      color: #657180;
      width: 4px;
      height: 100%;
      background: #F7F7F7;
      border: 1px solid #BFBFBF;
      border-radius: 2px;
      position: relative;
      &:nth-of-type(even)::before {opacity: 0;}
      &::before {
        position: absolute;
        white-space: nowrap;
        content: attr(data-point);
        top: -18px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translateX(-50%) scale(.7);
      }
      // &:first-of-type::before, &:last-of-type::after {
      //   position: absolute;
      //   white-space: nowrap;
      //   content: attr(data-point);
      //   top: 50%;
      //   transform: translateY(-50%);
      // }
      // &:first-of-type::before {
      //   left: -10px;
      //   transform: translateX(-100%) translateY(-50%);
      // }
      // &:last-of-type::after {
      //   display: none;
      //   right: -10px;
      //   transform: translateX(100%) translateY(-50%);
      // }
    }
  }
  .block {
    position: absolute;
    left: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%) translateX(-30%);
    height: 25px;
    border-radius: 2px;
    width: 18px;
    user-select: none;
    background: #F7F7F7;
    border: 1px solid #BFBFBF;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '| |';
      color: #BFBFBF;
      font-size: 12px;
    }
  }
  .moving {
    .block {
      &:before {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%) translateY(-15px);
        content: attr(data-point);
        white-space: nowrap;
        border-radius: 8px;
        color: #FFF;
        padding: 5px 10px;
        backdrop-filter: blur(2px);
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  .current {
    position: absolute;
    right: 5px;
    color: #657180;
    top: 50%;
    transform: translateY(-50%);
  }
</style>

<template>
  <div class="slider" :class="{moving: moving}">
    <div class="points" @mousewheel.prevent.stop="mouseWheel">
      <div class="point" v-for="item in data" :key="item" :data-point="item"></div>
    </div>
    <div class="current">{{current}}</div>
    <div class="block" @mousewheel.prevent.stop="mouseWheel" ref="block" data-point="" @mousedown="mouseDown"></div>
  </div>
</template>

<script>
var startX = 0
var offsetX = 0
var timer = null

export default {
  props: {
    data: {
      type: Array, default () {
        return []
      }
    }
  },
  data () {
    return {
      moving: false,
      current: (this.data || []).slice(-1).pop()
    }
  },
  watch: {
    data: {
      immediate: true,
      handler () {
        this.$nextTick(() => {
          this.current = (this.data || []).slice(-1).pop()
          this.update()
        })
      }
    }
  },
  methods: {
    update () {
      if (this.current) {
        this.$refs.block.style.left = this.$el.querySelector('[data-point="' + this.current + '"]').offsetLeft + 'px'
      }
    },
    fit () {
      const dms = Array.from(this.$el.querySelectorAll('[data-point]'))
      const offsetX = this.$refs.block.offsetLeft
      for (let i = dms.length - 1; i >= 0; i--) {
        if (offsetX > dms[i].offsetLeft) {
          if (i === dms.length - 1) {
            this.current = dms[i].dataset.point
          } else {
            this.current = (dms[i + 1].offsetLeft - offsetX) < (offsetX - dms[i].offsetLeft) ? dms[i + 1].dataset.point : dms[i].dataset.point
          }
          this.$refs.block.dataset.point = this.current
          this.$emit('change', this.current)
          return
        }
      }
      this.$refs.block.dataset.point = this.current
      this.current = dms[0].dataset.point
      this.$emit('change', this.current)
    },
    mouseUp () {
      this.moving = false
      document.body.removeEventListener('mousemove', this.mouseMove)
      document.body.removeEventListener('mouseup', this.mouseUp)
      clearTimeout(timer)
      this.fit()
      this.update()
    },
    mouseMove (e) {
      this.$refs.block.style.left = offsetX + (e.x - startX) + 'px'
      clearTimeout(timer)
      timer = setTimeout(this.fit, 100)
    },
    mouseDown (e) {
      startX = e.x
      offsetX = this.$refs.block.offsetLeft
      this.moving = true
      this.$refs.block.dataset.point = this.current
      if (this.data.length >= 2) {
        document.body.addEventListener('mousemove', this.mouseMove)
        document.body.addEventListener('mouseup', this.mouseUp)
      }
    },
    mouseWheel (e) {
      if (this.data.length >= 2) {
        // var x = this.$refs.block.offsetLeft + (e.wheelDelta / 2)
        // x > 0 && x < this.$el.clientWidth && (this.$refs.block.style.left = x + 'px')
        // clearTimeout(timer)
        // timer = setTimeout(() => {
        //   this.fit()
        //   this.update()
        // }, 300)

        let current = this.$el.querySelector('[data-point="' + this.current + '"]')
        let target = null
        if (e.wheelDelta > 3) {
          target = current.previousElementSibling
        } else if (e.wheelDelta < 3) {
          target = current.nextElementSibling
        }
        if (target) {
          this.current = target.dataset.point
          this.update()
          this.$emit('change', this.current)
        }
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
